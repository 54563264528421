import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout.js";
import "./mc-artboy.css";

const Artboy = () => {
  return (
    <Layout>
      <Head title="MC Artboy" />
      <h3>MC Artboy</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Brussels, Belgium
      </h4>
      <p>
        <OutboundLink href="https://www.artboy.co/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.youtube.com/@ArtboyInvisible">
          YouTube
        </OutboundLink>
      </p>

      <h3>Interview by Edward Alvarez</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F5d56b4ba-0419-47e1-9db5-a22c7216f473_1821x546.jpeg"
          alt="Edward Alvarez and MC Artboy"
        />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/WfE5LPzI-qY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p className="interviewItalic">
          Edward Alvarez: So I've watched and listened to several of your videos
          and songs, and spoken word things that you do, I have been trying to
          figure out what your message was. You have one music video where you
          talk about burning books but I guess it could have been more of like a
          poetic type of context. After learning more about you it seems like
          you are trying to just get people to think more critically about the
          world and themselves; is that sort of what you're trying to do?
        </p>

        <p>
          MC Artboy: I try to predict a little bit the world, but last time,
          like past few years, I wrote a lot, like a book, a full book. I wrote
          about the progress that I've seen that could come, that had already
          happened before, and the system and the patterns that we see in the
          world, like a Fahrenheit 451. I think the world is always in crisis. I
          wrote three books, the first one was in 2008, it was Hollywood is
          Burning and it was a book about the story of twin brothers, they
          travel from their hometown to go to Hollywood and become famous by
          becoming great actors, and it was all the tribulations of these
          characters who became legendary through their skills, through their
          brotherhood. And they had a cool name they were the Chaos Brothers.
          And it was all the story about the cinema, it was predicting a little
          bit, Once Upon a Time in Hollywood, in another way that Tarantino had
          written it, but it was in the same pattern.
        </p>

        <img
          src="https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F757bc110-d31c-4049-8035-5962bfad6ac9_508x686.jpeg"
          alt="MC Artboy"
        />

        <p>
          In 2012, 13, I wrote a book which was called Post-Modernity Is
          Dead...That was all about post-modernity, and when I wrote it I
          discovered that many things were some warnings, but also some good
          points about what was post-modernity from 2015 to 2021. So I was good
          to predict a little bit the future, or the big lines of philosophy
          that would change in our society. And my last book I wrote it from
          2017 to 2019, The Marvel of Days (Les Merveilles Des Jours) it was
          speaking in French about the end of literature, the end of literacy,
          the end of philosophy, and I wrote it just after I've been traveling
          with my magician friend. My magician friend and I, I motivated him
          after I had a dream. Because, to explain the full story I was with him
          to a festival and performance of art in France, and I was very tired
          that day. There was a Tarot reader that told me things about myself.
        </p>

        <p>
          As usual we always have a town where we meet, telling us things about
          ourselves. And I took the card and I said that's all right. She told
          me, "just take care of yourself and be kind, be quiet." And I came
          back home and I had a daydream. In my daydream I've seen this vision
          of Newton and Darwin, and I think the lines that came in my head were,
          Newton and Darwin are under a tree. There's a ladder next to it and
          Darwin says, "No monkeys ever fell from a tree." And Newton says,
          "There's no gravity in the ocean." And I had this vision in my head
          and I came back home and I couldn't sleep, and I told myself, what
          shall I do about this, this past few, this decade, this 15 years I
          lived in Asia that I came back in Europe, in Brussels. And I had this
          thing, and this dream, and then I don't know, it was like a blink of a
          second, and I thought to myself, 'I need to see the tomb of
          Nietzsche.' And with my magician friend, I called him and I say, I
          want to travel with you, let's go somewhere.
        </p>

        <p>
          And we've been traveling around Europe, like first in Germany seeing
          the tomb of Mesmer, Franz Mesmer, the hypnotist. And then seeing the
          tomb of Nietzsche, traveling in UK, seeing the tree where the apple
          fell, where Newton discovered the theory of gravity. We've been seeing
          the tomb of Da Vinci, and later traveled to Serbia to see the place of
          Nikola Tesla. And later on I've been to United States, seeing the tomb
          of Harry Houdini, Jean-Michel Basquiat. I've been traveling to Israel
          and Palestine, seeing Golgotha, the place where Jesus was crucified.
          And in my head I wanted to discover a little bit the reality of
          philosophy, the reality of...at the time it wasn't about spirituality
          because in Asia I've been seeing all the temples that I could, and I'm
          very much, I'm not the esoterical person or exoterical person, I'm not
          a mystic, but I understand this thing...like I have an instinct for
          it.
        </p>

        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F33d3c5a7-4cdc-49a1-a34d-c86578e5a98a_1125x604.jpeg"
          alt="MC Artboy"
        />

        <p>
          During these times I was pushed by the fountain of philosophy to write
          a book about the end of philosophy that I foresaw in my brain, or in
          my heart I don't know...When I was traveling I had this temperament to
          speak about philosophy, the end of philosophy, and I seen that books
          are not what mattered anymore like before. Like philosophy is a
          sociology to explain the things that are outdated for many. Now it's
          the time for beauty, for the images. No more books are needed to
          explain, we need feelings, the morality is love. We do not need to
          read the book any longer, the morality of the book, like the morality
          is love, just love yourself. Love others. We do not need to see the
          guy with the 1,000 faces, and it doesn't need to suffer to vanquish a
          dragon, it doesn't need to travel the world, and have tribulations.
          The morality is just love yourself, love where you are, love what you
          do, and I think in my mind I had this feeling, I wanted to write about
          these times, about poetry, about all those things that mattered
          beforehand.
        </p>

        <p>
          I've seen maybe the end of the power of words in the black and white
          way. And I think it's powerful to witness the end of history, like
          Francis Fukuyama said. Around 20 years earlier he spoke about the fact
          that freedom would win, that people would live in some kind of
          economy, everybody would be free, there would be no more conflicts,
          there would be one world order, no more borders, and everybody would
          live on the power of harmony, of peace, and there would be no more
          wars, no more conflicts. Lately there was the war in Ukraine which
          changed things, but slowly we are coming to the globality of this war,
          like the global leaders are making the world a circular world where
          there's no more limits for us to be soon perhaps in peace together
          living in equality, in freedom, in liberty, in love and in kindness,
          in brotherhood, all these things. So when I wrote I wanted to speak
          about that...and then I made a lot of slam poetry, like in open mics,
          and in theater.
        </p>

        <p>
          So like in London in front of the Ministry of Defense with Extinction
          Rebellion. I used poetry to speak about the difficulties that mankind
          could still face before this end of history. So I performed in NYC, in
          Jerusalem, I wrote poems about my experiences and I felt that there
          was a problem with myself and this problem was this expression that I
          had, which was to be, to have a wording that was maybe out of these
          generation's new style of writing. I was writing like a 19th century
          poet, but not like a 21st century. And I wrote, and I seen that people
          did not really understand what can be done with literacy, what grammar
          can express, it was a little bit abstract for people. And I had
          problems, I did the images, I'm an artist, so I make graphics. I
          started in 2010 to make graphics and was exhibited in different
          galleries around the world. I had the name Kazu Livingstone. I always
          wanted to change the world as I can, or to protect it, like a
          guardian, not like a guardian of the galaxy, but a guardian of the
          earth and to express things that can protect others but can also
          enhance their thoughts, their system, and their ways of being.
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/_FeECS4Mb9A"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p>
          I express things and with images and words I try to change the way of
          my existence, and make people change. And at some point I understood
          that that wasn't what I wanted to continue, in the style that I was
          writing, I didn't want to continue to write in the same way. So now I
          make music and it's different. I make rap music, and in French mostly.
          And it's not the same because we cannot write about the beauty of
          philosophy in the same way. Now, I try to trash...Washington DC, or in
          countries where there is corruption, I try to make things simple for
          people to understand. It's not the same way to write in these times
          and things change, and my style is changing, and I think I do
          something very different now from what I've been writing before. Each
          project has a different style of writing, and each project doesn't
          speak the same way.
        </p>

        <p className="interviewItalic">
          EA: I forgot to say, I know after listening to a lot of your different
          music videos, and like poetry and stuff, that you talk about different
          subjects. Like you talk about the war, different war stuff, like
          politics. I don't know how to explain it, but you talk about different
          things around the world, and you know, different subjects, it's not
          just poetry, you have other things that you think about. I'll just say
          that you could talk about anything that you want. Some of my past
          interviews I have just been talking to musicians and artists and
          stuff, so this is more than I usually have been talking about, but I'm
          open to whatever you have to say. So how did you start writing your
          books?
        </p>

        <p>
          AB: I was born in Georgetown, Malaysia, which is next to Thailand,
          next to Indonesia. I lived there for like one year and a half, and
          then I've been traveling with my mother at the time, and we've been to
          India, and after that we've been coming back to Brussels, my mom is
          from Belgium. And my dad was in the Malaysian military, but there was
          a very complicated situation, and we had to part ways. I've been
          living in Belgium 15 years, from age of two years old to at least 17.
          At 17, it was in 2001, like August, and next month there was the
          September 11th, and I don't know, that was the fate of life. Then in
          October we already had come, and I lived with my mother in Asia for
          one year and we travel over there, it was one month after 9/11. And my
          life completely changed, I had this passion for writing. I always had
          a pen with me, and it took me some time, like six months. And I
          discovered that my dad died in April of 2001.
        </p>

        <p>
          I lived a little bit in Malaysia, and my dad, everybody knew him,
          everybody loved him. And many people, they spoke to me with much
          respect of my dad, but they were very complicated situation. I had a
          box that I received from him. After I've been living there in
          Malaysia, there was a box for me, and there was a pen in it. And I
          always liked writing my emotions, writing what I felt. I had a long
          distance relationship with a girl in Belgium that I really liked, and
          we spoke for many, many years and I always liked writing very deep and
          profound things that were always lost. Like for me, writing is not
          specially something that I can keep; I lost books, I lost poems. I
          always wrote things that matter to me, but also matter to some of
          humanity. That was my way to express what is my heart, my brain, my
          emotions, but also what I was thinking about history, about wars,
          about tribulations, about the death of my father, about the situation
          with the conflicts of civilizations, it really helped me to fathom a
          little bit more about this world which was very complex.
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/VXcgeMrBDHw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p>
          The first decade of the 21st century was very complex to me, I had to
          understand things that I couldn't understand with school. School
          wouldn't teach me what I needed to understand. To understand this
          country, these words, the languages that I encounter. If you live in
          Asia for a long time it's very difficult because you can learn a
          language, but you cannot speak all the languages. Like there are
          types, there are different ways of speaking Chinese... There are
          idioms, there are ways to speak, which are different in different
          parts of Asia. So you can learn Japanese, but you have to learn
          Chinese, you have to speak Thai, you have to speak Indonesian. I'm
          mostly speaking English now because it's too complex to be understood
          by many differently, but the way to speak, the way to explain the
          deepness of our own history, we are all historical people, we are all
          experiencing the difficulties of change, that it be political or
          sociological.
        </p>

        <p>
          We are witnessing the change-ment, the technology, all these things
          are changing in a way that, yes, time matters, and writing has always
          been something very important for us to make sense of what is
          happening around the world and within ourselves, everywhere. And so in
          the ways of Charles Bukowski or Ginsberg, or perhaps Kerouac, we are
          in an adventure. I had the luck to be traveling around many places in
          the world. I've been visiting Egypt like really deeply, and I had so
          much chance to meet people that were my guides, were my protectors.
          I've been visiting old museums, many museums, like most museums in
          Europe, in America, and I always had this passion for words and
          images, and I tried to keep a diary that some people will not read or
          see. I think expressing ourselves makes us understand who we are
          better. Like if you ask me what is the definition of our mind? It’s
          like a typewriting machine, and this typewriting machine writes things
          for us.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F60fb675e-cc08-49f2-9917-51f236097904_893x668.jpeg"
          alt="MC Artboy"
        />
        <p>
          There is meaning in the world, but there is also meaning in how we
          find a world where our instinct speak, we are a door for, not
          specially in the spirit, but for meaning. It makes sense, the world
          makes sense, like there is a stair, there is a ladder, there is a
          door, there is a home, there is light. It's, in the words of Einstein,
          it's pretty amazing that the world makes sense. And the most complex
          things in our world is that there is grammar, there is logical ways to
          communicate. Evolution made us live like a very long time without
          sense, but at some point a monkey opened the door, he had the key, the
          monkey had the key, and he opened the door, and at that point we
          started to make sense, and the words that we use, is like phenomenal
          that we can be understood. Words can be used to be understood by other
          persons, and there are meanings.
        </p>

        <p>
          There are ways to express things, there is karma, there is definition,
          there is Wikipedia, there is history, there are names, synonyms, all
          these words makes sense. So words are the most important thing that a
          human being can master. I can read books from the 20th century to 17th
          century and I understand them, I understand what they felt, and
          through words we can be understood. Images can help, but still images
          can trick us to believe things that are not only real but there is
          also fantasy. Words can lie, but words can express reality in no other
          way. So write, to write is important to understand ourselves and also
          to understand the world.
        </p>

        <p className="interviewItalic">
          EA: I think you're really eloquent in a lot that you say. You seem to
          have a gift for speaking, that's what I notice, which is really nice
          actually. I'm really enjoying the conversation and we'll just keep it
          like this. We'll just enjoy talking to each other. I think what you're
          saying seems really important. You're really articulate, the way that
          you articulate things. It seems like you have a lot to say, I guess we
          all have a lot to say. There's a lot that you say that resonates with
          me. It's a lot of similar things that I think about also. So there's
          definitely a lot to talk about. I studied literature, I studied
          comparative literature. So like world literature I guess is another
          way to put it.
        </p>

        <p className="interviewItalic">
          I get a lot of your references, like the thing about the thousand
          faces, Kerouac, and all the writers that you mentioned, and the
          magicians, and yea it's really interesting stuff. That's great that
          you were able to write those books. I guess it's something that I've
          been thinking about also, like maybe I should write a book, somebody
          told me told me that once. It’s great that you have this impetus to
          just do everything that you want to do. Your art, your writing, your-
          everything that's important to you, it seems like you're trying to do
          it. And it sounds like you might be a futurist, like futurism.
          Futurists are people who try to predict the future, and try to see how
          things will change in the future.
        </p>

        <p>
          AB: We call it futurologists...We live in a world which is very
          complex, all of history is being questioned. We are questioning
          history, we're questioning evolution, we are questioning Darwinism, we
          are questioning if the fact that the world exists, if this world is
          not specially a dream, but a simulation where numbers and coding have
          created the mind of people. Things are changing now. The books that we
          had before are not updated compared to what we are living now. I love
          technology but I still have lived the stories of humanity. I've seen
          the beauty of history, and like I'm a traveler, and I will travel
          through time, through ideas, ideologies, everywhere I go I try to just
          say yes. Yes I listen, and I always listen to people's history, and
          people's way of life.
        </p>

        <p>
          I will never judge people. Like the morality of the Bible for me is
          that in the end we need to stop judging others, it's called the last
          judgment. So my goal is never to judge people...And so I'm a warrior
          and this warrior that I want to portray, is a warrior of harmony, of
          understanding. And when I was living in the past, I've been trying to
          go everywhere in these times...and everything about technology, about
          science, about the post-modern ways of life. I try to understand and
          travel around time. Time is the most important thing for me, I want to
          be a time traveler but not in the sense that I want to travel in the
          past with a machine, I just want to travel through history, books and
          reality.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F82dd7647-9a2a-43cb-807c-e23ff8f083cc_689x685.jpeg"
          alt="MC Artboy"
        />
        <p className="interviewItalic">
          EA: It's so interesting just talking to somebody from another country
          because sometimes I just feel like we're living in different worlds. I
          have the assumptions sometimes that we have a lot of differences, but
          it seems like we're not really that different, or something like that.
          Some of the things that you mentioned about learning the things that
          have already happened and then using that to extrapolate into the
          future reminds me of, I think it was the Italian writer Giovanni
          Boccaccio, he said, one of his famous statements was, "you must read."
          Basically he meant like read history, and then learn from history, and
          all that stuff. I was going to ask you, do you think it's possible
          that the world would end before 2068?
        </p>

        <p>
          AB: It depends of us, but the thing is, it doesn't really matter. In a
          way, yes, the world would end, but how? Not why. Because the world is
          evolving, we evolve, and we are supposed to understand the errors of
          the past. Things are changing but we cannot have apocalyptic mind. I
          know many people in my family, or around the world that always think
          the apocalypse will come, we have no time for it. We have to, yes, we
          can watch the news, yes we can feel upset that things are not working
          well. I know what's happening, I watch my newsfeed, I read the New
          York Times, I always have been fascinated by the news media, or maybe
          CNN, even Fox News, everything. I watch MSNBC Economics, I try to
          understand things in the news in Asia, Japan, the news in China. To
          understand things happens when we understand the feeling of what is
          history. And yes things will go very complex, but we do not always
          have the proper leaders...
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F203b9a38-fe6b-4b6d-a1f2-10906911e505_732x595.jpeg"
          alt="MC Artboy"
        />
        <p>
          The destiny that we have and destiny that the world has, is just like
          that. It means if it ends, it ends, but we can always together think
          how to make the prison better, or to change the world, but we really
          need to be going forward too. Like yea, in 2026 things will change.
          Now in the news, like in Europe, the energy crisis is coming really
          strongly, it's perhaps a big crisis also for the electric cars, or the
          energy bills that the people receive. And that's tough, and yea in the
          five following years after 2022 there will be changes. Yes there will
          always have been possibilities that the world could end, but the world
          is always changing. There were plagues before, there were world wars,
          yes we are in a situation where it's becoming much more easy to have a
          situation where there are catastrophes, there were Fukushima, the
          Pacific Ocean was almost destroyed, there was almost new wars as big
          as the Vietnam war that could happen, there were wars around the world
          that changed the fate of history, the fate of science.
        </p>

        <p>
          And we need to think about the future, and how to think about the
          future is to make the world a safer place, to think about what we
          need, what we actually need. Things are changing all the time, but
          there are ways for us to be safe, and how to be safe is to understand
          the past, to understand reality, and we have to aim for the stars, but
          the stars can be in Earth, they can be anywhere. There are things that
          have light and if we see the light, if you understand the infinity of
          possibilities, if we accept that other people, and other civilizations
          have different ways of life, if we understand that there are multiple
          tools in the world, if we forgive others, if we love humanity
          unconditionally, if we understand that people have different point of
          views, if we forgive the mistakes of our families, if we are forgiving
          towards ourselves we become compassionate, if we love science we can
          learn maybe to add this technological understanding, if we understand
          spirituality we can understand technique.
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/upYpgp1BDKA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p>
          I think that the world was changed when we began from the words system
          of technology, we changed from technology to I.T., intelligence
          technology. Now there is ways to communicate, like telepathic machines
          that help us like big brothers, where we film everything, and that's
          all right. There are lessons in everything, we can learn from
          everything, everybody that we need is knowing something that we do not
          know. People have sufferings, and there are lessons in history, but
          there are also lessons in ourselves. So if the world ends now, so
          what? We had the chance to become better, and there's only one way for
          it to end, is that the time is up, and I'm not sure the time is up
          now, I think we have many things to accomplish. And we will understand
          deeper things than we have understood by now, there are many, many
          doors that will open in the future, new technologies, things that we
          cannot even understand right now.
        </p>

        <p>
          There are things that can really change the world and it's towards us
          that we can project this, this hours of time. I think that we can
          communicate with others to understand things that will really help the
          world to launch new vibrations, new ways of understanding what is
          reality. If we understand what is reality the world changes, so it's
          right now that many people will do things, to new technologies, to new
          philosophies, new ways to approach life, new ways to communicate with
          others. Things are just starting, and there is a revolution right now,
          and this revolution will change history, so I'm not sure that we will
          see the end of time until the time is really up, and it's not up right
          now. And in 2068, yea, the world will live on different ways of
          thinking but it's just the start of our humanity, the possibilities
          are endless.
        </p>

        <p className="interviewItalic">
          EA: Yea, that gives me a lot to think about, and it reminds me so much
          about some things that are on my mind sometimes. I think you've done
          maybe some more thinking than I have, but I try to think about the
          future sometimes. You were talking about, I forget the word that you
          mentioned, but I think you also kind of were referring to intuition,
          and I kind of have this feeling that things are going to change a
          certain way in the future, that maybe don't make sense to us right
          now. But it's also so hard to think about those things because our
          current experiences seem so defining of the way things are always
          going to be, and like we kind of, I feel like we get stuck sometimes
          thinking that things are always going to be a certain way. And if you
          go back into, you know, learning different periods of history, it
          seems like that's how people have always thought throughout time, is
          that they always thought that things were going to stay a certain way,
          and so yea it's a lot to think about.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F9d3b9767-53a4-4063-8c86-2ab01d953c52_979x691.jpeg"
          alt="MC Artboy"
        />
        <p>
          AB: Things change, things change all the time. We cannot have an
          apocalyptic mind. We cannot see the dust of history, we need to see
          the sunshine of history. Some positivity is good, we cannot be naive,
          we need to be real, we need to think correctly about what's going to
          happen, but the world, we need to see not especially the good, nor the
          bad, neither of them, but we need to see what is interest, what is
          changing, and what really matter. Yes there are problems in the world,
          but we need to solve these problems, and how to solve these problems
          is by thinking and to solving solutions, and I think many people in
          the world are finding solutions for world problems. I'm positive, some
          changes in economics can help, some changes in energy can help. Yes
          there are people that find solution every day, but they are not in the
          mainstream media, or they're not an artist...but these technologies,
          these new ways to communicate, to share history and technology, these
          people they are changing humanity. I don't see the bad in the
          world...I see that people have possibilities to bring the light in
          everything, and there is no light without some darkness.
        </p>

        <p className="interviewItalic">
          EA: Yea, it's the yin and yang thing also from Eastern philosophy, but
          I think what you're saying is really meaningful. I was going to ask
          you, what type of artists have influenced what you do? I think you
          mentioned that you were inspired by Prince?
        </p>

        <p>
          AB: Prince, yea that's a complex one. I love the Harlem Renaissance.
          If you ask me music, or if you ask me art, is different. Like I'm
          interested by so many things, good poets, good writers, good artists.
          There are many ways to express creativity, and Prince shaped some part
          of my storyline. It's a complex one to explain, because I had an
          adventure in New York City that made me change my personal experience,
          I had many friends that changed my life, there are many artists that I
          finally met during my life after some adventures. I have met some of
          my heroes. They say, "Never meet your heroes, you're going to be
          disappointed." But I met some of them, some people really touched me.
          I always find a way to, at the corner of a street, meet somebody that
          is really famous, and changed my life for five minutes, or 10 minutes,
          or 20 minutes. I've seen my greatest heroes in concerts. I perform in
          a way that I'm not especially disappointed with my own skill.
        </p>

        <p>
          So I feel I have no heroes, I have no persons that I really venerate
          anymore. I'm not somebody that thinks that there are people that I
          should worship. I feel like I have friends, and I want to work on
          myself better to become one of them. I think fame is not that
          important. The heroes that we see on TV, or MTV, or the biggest names
          on YouTube, they are people that already worked on themselves and they
          developed the skill, and they made amazing things, like performances
          or concerts. I love African American culture, I love Japanese culture,
          there are performers and painters that made amazing things...there are
          many writers that wrote beautiful things. And I'm not impressed
          anymore, but I feel these people they shaped my youth. When we're
          young we think, 'oh these people are amazing, they're worshipable,
          they made things before other people.' As I visited all the museums,
          I've been visiting the houses of Salvador Dali, of Picasso in Europe.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F70726389-313d-4491-8888-2a693cf0516e_696x696.jpeg"
          alt="MC Artboy"
        />
        <p>
          I've seen real paintings of the most amazing beings on Earth. I
          started to pierce the veil of history. And yes there are things that
          shaped me in a way that I discovered that, yes, humanity, if you work
          hard can be amazing, there are artists that shaped the world, that
          shaped history, they also shape our hearts, and they made us better
          persons, they made us learn what is the definition of beauty, and
          beauty is something that people didn't especially understand. What is
          beauty? Is it novelty? Is it something new, something we've never seen
          that technically never imagined that can be? Yes there are artists
          that made new techniques, that changed the world with different ways
          of approaching life, approaching imagination. There are people that
          have dreams that became reality. We are all artists, we're all making
          the world seen by a different angle, and that's tough to portray
          reality in different ways that other people have ever seen. And yea
          Prince is one of them, Saul Williams is one of them, there are too
          many artists that shaped my consciousness, they were a part of my
          personal consciousness.
        </p>

        <p className="interviewItalic">
          EA: Yea, it's really interesting. Prince, I think I kind of found out
          about him later in my life, like I didn't really know very much about
          him growing up, but I've learned more about him lately...I really
          liked a lot of the things that you said…It's so interesting that
          you've been able to travel so much…Do you play any instruments?
        </p>

        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F02e5d1a8-110f-4185-8a4e-b343d943a367_1481x540.jpeg"
          alt="MC Artboy"
        />
        <p>
          AB: When I came back to Europe I studied most instruments, like I had
          one year and a half of studying the techniques of the guitar, the
          piano...I can play, but it's not something that I want to do. I
          understand some of it. The understanding to make music is something
          important. At the moment I am more about the lyrical stuff, the
          writing. I think that many people, many of my friends they use
          instruments to express their feelings, I'm not here to express my
          feelings...That's not exactly what I want to do, I want to create a
          message, to make people change. At first I wanted to learn music, and
          to play everything, to be versed in everything, and to study is
          important for me too. But at some point I understood that message was
          much more important, because it could change the mind of somebody, or
          make them think that they need to understand things that they haven't
          understood before. So that was important for me to use words, and at
          the moment I focalize on not especially playing music, but to be with
          other people that can make the job for it. So yes, words are like my
          mighty pen, my mighty sword that express things that others can
          understand. So words is my instrument at the moment.
        </p>

        <p className="interviewItalic">
          EA: Yea, I like that. Earlier you were talking about meeting your
          idols. What is that experience like for you? Do you get nervous
          talking to people that you admire?
        </p>

        <p>
          AB: I think the first time we meet a hero, it depends at what point of
          our life we meet them. If we meet them when we are ready for it, it's
          not the same that if we need a master. I've met masters that weren't
          my idols. And my idols didn't have time to express or to teach me what
          I needed to learn. Maybe that was just meeting, like they say the
          great mind meet. So yea I've met people that brought me a different
          perspective, but they weren't famous. And these people they taught me
          things that no star in Hollywood can make me really fathom. When I see
          NYC, I've been to places where I've met amazing people, and these
          people they taught me things...My goal is to be with people that are
          amazing in their skills, whatever if they're famous or not, and that I
          can be friends with them, or best friends with them, or be
          contemporaries. And some people I would love to spend all my day with.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F6bbce9ec-62b5-4a07-b483-44d5542a2e3f_1107x617.jpeg"
          alt="MC Artboy"
        />
        <p>
          I guess some artists when they're famous they can stay with people
          that are famous like them. Some artists can spend time with the
          greatest minds of this world, and that's amazing to be able to be with
          people that are really intelligent, really crafted, that can make the
          world as beautiful as the stars...I want to be so good in my own skill
          that people they just want to be buried next to my own grave because
          they respect. I feel that many people they just want to touch the same
          glove as Eminem, or let's say great artist, or great writers. But in a
          way that doesn't really matter...I never want to meet the stars, I
          never want to meet more famous people than me, because I just want to
          be the best that I can, and to learn in my own solitude for one day to
          be able to be best friends with them. Because otherwise there's no
          point, I just want to progress myself. I want to be alone, I never
          want to meet people, I just want to work on my skills and become the
          greatest artist that I can so that I can have great best friends and
          to be able to meet these people and so to interact with them daily,
          and to speak in a way, through poetry, through music, through art, to
          share with the best artists.
        </p>

        <p>
          We need contemporaries, and we need to speak with people on the same
          level. But that's pretty hard because we need to have a craft, we need
          to have a skill that allows us to speak in a way that is respected by
          everyone. And I feel that there are some artists that crafted so much
          their skills that they made themselves legendaries. But yea, I've met
          legends. I've met people that I'm really proud to have met, but that's
          just like seeing your shooting star. Yea, you can make a wish, you can
          speak to people, you can tell them your own story, you can tell them
          how they inspire us to become greater persons, and I wish to have more
          time with them one day, if I work well on my own skills.
        </p>

        <p className="interviewItalic">
          EA: Yea I really like how you explain that, especially that part at
          the end. There's a lot there...So I'm wondering what's your experience
          living in Belgium?
        </p>

        <p>
          AB: Brussels is the capital of Europe. I've been able to live in the
          center of European politics, I've been able to be in a place where I
          fulfilled my own destiny. I've been able to live in a place where I've
          seen the most beautiful things, the most beautiful people, the most
          beautiful artists...It's really modern, there's a lot of people from
          around the world. I've lived in Malaysia. Malaysia is a multi-cultural
          country, there is a third of Malaysia which is Chinese, a third of
          Malaysia which is Hindu, and there is a third which is Muslim...But
          Belgium now with the time has become really the place where all
          cultures, all people, all beautiful people, all talented artists
          meet...There are still great artists in Belgium...The world is
          changing, nothing is like it was before...
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/HtvAqLAkOic"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p className="interviewItalic">
          EA: You really do have an interesting accent, but to me you just sound
          like you're from France. So how many languages do you speak?
        </p>

        <p style={{ alignSelf: "flex-start" }}>
          AB: Let's say two: English and French. I can speak a little bit of
          different languages, but two.
        </p>

        <p className="interviewItalic">
          EA: Being bilingual is still impressive. I don't understand any
          French. I know that it's similar to Spanish in some way but I never
          really tried to study it. It seems like a complex language to learn.
          Do you have an opinion on psychedelics?
        </p>

        <p>
          AB: No, I always was fascinated by The Doors of Perception. I wanted
          to understand what was the meaning of life, but I'm not sure it's with
          psychedelics that I will find it. I feel that, like Salvador Dali
          said, "I am drugs." Drugs or like psychedelics can help to open doors
          and perceptions but I'm not sure that we cannot open it differently.
          Like for example, I feel that religions helped in some way before, it
          helped to open doors into perception. Now people they go different
          ways, some people they go into retreats, they make a wish of silence
          for two weeks, like the people that really want to make their brain
          anew. There are ways to open doors into our mind and that's complex to
          do, but there's not only one way, and yes there are within the brain
          amazing possibilities to understand what is really reality.
        </p>

        <p>
          That's tough to open them, and psychedelics can, but that's something
          which needs to be worked on within yourself to open doors. But how to
          open a door? That's difficult to open a door that is closed, there are
          different ways. And I'm sure we all have our own facilities, we all
          have possibilities to open a new way to understand the world...Some
          people they will read books, some people they will take drugs, some
          people they would meditate, some people would trust into religion,
          some people would find the solace into economy, and magic, witchcraft,
          some people they would study astrology, some people they would study
          philosophy, some people would see the meaning of existence into
          mathematics, some people would find the energy and the vibration and
          the personal happiness and the self-care, self-love as the meaning of
          life itself. But some people they want to live within their heads and
          imagination. Some people trust into fantasies, some people read books,
          some people write books, some people believe in fiction, some people
          believe in science-fiction.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F2883f0ac-8b48-4bb3-af1d-c0419ac7df2e_524x515.jpeg"
          alt="MC Artboy"
        />
        <p>
          There are many ways to understand the world, you create the meanings
          as some say. Is what we see an hallucination? Is the world a
          simulation? Does the world exist? Is dream important? Is there a
          science to understand what is real into a dream, and what is not real
          into a dream? Like can dreams become a reality? If you close our eyes
          and we see a dragon, or if we see a beautiful fountain, is it real?
          Like dreams, can we touch them, can we make the world more beautiful
          by hope? Hope is important. I hope I can see things I've never seen,
          but yea, reality is cool too, dreams are cool too, fiction is cool
          too, everything is cool. So you can travel the world but you can also
          travel your mind. You can travel your dreams. It seems that in dreams
          there are infinite possibilities, and in the world there is less
          possibility because you're grounded by your bank account, what money
          you have...Education is important: how to free yourself from the laws
          of physics, the laws of economics.
        </p>

        <p>
          I can live in my dreams, I can live in reality, and that's pretty much
          the definition of the back and forth between the real and the dream.
          If I take psychedelics I will go in one direction, if I study in
          school, or if I go in a religious school, or if I become Buddhist, I
          will learn a different way. We all have a different path for
          enlightenment, and this enlightenment can bring us towards the deep
          meaning of existence, and you choose what it is. Some people they
          believe it's money, some people believe it's to have nothing, some
          people they live with nothing and they are pretty much happy with
          where they are. Some people they get a simple job and they end the
          economics fixation by just living next to a beach, drinking a cocktail
          with a beautiful lover. Maybe love is important. Maybe reality is
          important, maybe fantasy is important. Star Wars is pretty amazing,
          but it's a fantasy at the moment. So cinema can be also a form of soft
          way to approach existence, which is not real but in the same way is
          pretty much predicting what can be done. Everything is important to
          open the ways to enlightenment.
        </p>

        <p className="interviewItalic">
          EA: Yea that's a good point...It's interesting what you said about The
          Doors of Perception, that was Aldous Huxley. That left an impression
          on me when I was reading it a few years ago, his experience actually
          was with mescaline, which isn't a very common, I mean it's not
          necessarily what most people think of when they think of psychedelics,
          but I guess he had a really interesting experience with taking that
          and then he ended up writing that book. Anyway I think you have a
          point that there's different ways of enlightenment, and supposedly
          also with like Eastern philosophy there's the idea of Samsara, which
          is like the wheel of rebirth, where if you don't reach enlightenment,
          you get stuck in Samsara or something like that. Do you do any
          meditation in your life?
        </p>

        <p>
          AB: When I was younger, when I lived in Asia I practiced Buddhism
          quite a lot and meditation, but not especially like the western way…I
          really had a struggle with the economy in a way where I wanted to
          retreat from the big cities and live next to the blue seas. I swimmed
          with the dolphins, but then in the same way I approached life as an
          illusion. Like things can be pretty much fictional when we have a job,
          we have a family, it seems like a fiction for some people because we
          all create an ego, and when we create an ego we say, this ego matters,
          like my life matters, what we see in the news matter, the bank account
          it matters, our family and our ancestry, everything that we live
          before was important for defining who we are. And at some point I
          didn't believe that individuality was who I was, I believed there was
          nothing. As I lived in Thailand, in Malaysia, Indonesia and Southeast
          Asia, I traveled through spiritualities, I opened doors of
          perceptions, but also doors of realizations inside myself.
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/MM4o0sBzhWU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p>
          I didn't believe in my own individuality, I believed I was a medium
          for life itself, for spirituality. I studied Islam, I studied
          Buddhism, I studied Hinduism, I studied capitalism…and in the end I
          can be a heart that communicate with different ways of thinking,
          different ways to approach the existence and so yea meditating is what
          I do, I always do it, but at times I'm wide awake, or maybe I'm
          walking. There are ways to meditate which is not specially the way we
          pray, or we go to church, we can walk and meditate in a way. Yes the
          end of thoughts which are noise, I heard somebody say that we all have
          useless thoughts inside our mind. And that's the way to work on
          ourself is to scrap the noise that we have inside our head. My mind is
          pretty much empty all the time, but I communicate, or I communicate
          with you, I communicate to others but it's the way the spirit becomes
          individuality within ourself, and communicate with others, but there’s
          somewhere here, something which is important which is that there is a
          way to express things which is not limited by individuality.
        </p>

        <p>
          Many people created what we think because of the patterns of humanity,
          and also we have two ways to describe the world: there are the people
          that think they are individuals, and there are people that believe
          that we live in a society. There are people that want to progress
          their own individuality, and there are people that wants to protect
          the groups that are tribes, that are communities. There are people
          that believe they are one, and there are people that thinks they are
          many. And like it's the same thing about spirituality, about
          meditation, is to open a door with the spiritual, but what is the
          spiritual? To define it which is important. And what is spirituality?
          What is the end of noise? What is communication with everything, with
          dreams? Only science is something that people could have if they
          opened the door, to the real door of perception which is the world of
          the spiritual, and there are many ways to attain the enlightenment.
        </p>

        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F0cbbff9a-a45c-495b-bca3-7c3ab71d272e_521x700.jpeg"
          alt="MC Artboy"
        />
        <p>
          We can attain the light within ourself, but what is one, what is many,
          what is individual, what is community? Are we communicating to what
          person, or are we communicating to everybody? Some people they believe
          that God is everywhere, that God created the world, but those are just
          questions that we have, and through meditation maybe these questions
          can be answered, maybe not. So yea to be alone with ourselves, to
          close our eyes, to meditate about the meaning of existence, to
          understand that this world is a creation of ego, that we are around
          people that are ego. The goal of meditating is to understand what is
          individuality, if the world and if our perception are right. There are
          people that understand the world through science, through
          understanding the meaning of life. There is in fact no question, what
          is the real question? The question is are our eyes seeing properly the
          world? Are we able to see the horizon which is inside ourselves? Are
          we seeing the sunlight for what it is? Because light is pretty
          powerful and without light we wouldn't be able to see anything.
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/zg6kOomr1Y0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p>
          So yea our perception is seeing many, many things, are we seeing the
          truth? Because if I go outside I can see cities, but for example I see
          in the news, I see things and I know that where I am at the moment
          it's not easy to see reality just with our own eyes. We need the eyes
          of others. Things have changed, like with the computers, the
          telephones. Meditation is cool and it's important to understand what
          is the ego, but when we see a phone we see that the world exists, that
          there are events even if we die, or if we sleep for 40 years the world
          would still exist. So yea we ask ourselves the question what is
          reality once more. Because to understand ourself is something
          important, to understand the world is another thing, we need to
          understand both. So at some point we need to meditate about the
          greater things in life, and yea the world exists in many, many ways,
          and that's what I think. Meditating is important, but understanding
          the world exists is important too.
        </p>

        <p className="interviewItalic">
          EA: There's a certain word for meditating while you're working on
          doing something, but I don't remember the exact word for it, it's a
          Sanskrit word, but I'm forgetting what it is right now. There is that
          idea of putting meditation into everything that you do, instead of
          just sitting down and thinking like I'm gonna meditate now. It's more
          of an active thing, it kind of tinges itself into everything that you
          do… What are your thoughts about money?
        </p>

        <p>
          AB: Money is cool…but money is very deep, it was a metaphor for
          something, the metaphor of hard work, of success. Greed is good,
          that's what the movie said. Yes money is cool, but we need to see what
          it is. It's something important for people since a very long time,
          because at first we couldn't market ourselves or make exchanges
          easily. Now since Adam Smith with the stock markets things have been
          really different, like in NFT, also in the digital art. It's like the
          value of things are really changing, because the world can change the
          value of things pretty much easily. I love money, but I don't live by
          the system that we created now. Like the things are absurd, or maybe
          not absolute, or not fair. There are rich people that never worked
          enough to be that rich, there are people that are poor that worked
          much more than anybody, there are people that suffered a lot and
          didn't get any dime from their suffering. And pain makes a gain, and
          the world needs to be fair, but that's also fair not to be fair,
          because if everything would be fair it would be too easy for some
          people, or too hard. It's difficult to make the world as fair as
          Vanity Fair.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fbb798c23-6dfd-4494-bb43-b0b8cc0b6ef1_710x482.jpeg"
          alt="MC ARTBOY"
        />
        <p>
          If you’re rich, if you have money, do you have vanity, do you have
          pride from it? Are you happy with it? Is it the pursuit of happiness?
          Are rich people happy? Are poor people sad? Some people are really
          poor and they are really happy, and some people are really rich and
          they are really sad. And there is the movie American Psycho, or there
          are movies about the success of people that were hard-working and made
          the world better from it. Like Elon Musk really changed the perception
          of somebody rich for a while, and there were people that never changed
          the world by any means, but they became pretty much rich. Is it fair
          money? I don't know. I had the chance to witness different societies,
          different ways to approach it, but I'm not sure what is the the final
          word of what is the better way to practice the system, is it
          capitalism, is it communism, is it socialism, is it individualism? Can
          we make the people happy in many ways?
        </p>

        <p>
          It's impossible that we are all happy, would the world be fair if
          everybody would be happy? I suppose yes, but as I’m black and white I
          believe there are some people that need to work, and there are some
          people that need to work harder. But yea I hope we can pursue
          happiness, I hope we can pursue the ways to become who we really are,
          and we need to work to understand who we really are. I need to get up
          in the morning and tell myself, yes I can become better. And how to
          practice our own skills, our own crafts. If the thinking of many
          people is that you need to make efforts, you need to work every day on
          a good idea, to bring it to the world, to manifest it and to finally
          make dimes from it, to make money from it, and finally become your
          ideal self. I don't know, I feel nobody should feel cool to have
          suffering. But yea there are two ways of thinking, the individual and
          the community. Yes there were crimes in history, yes the world is
          unfair, yes there are people that are very, very rich, and there are
          people that are very, very poor…I don't know, it takes time to decide
          how to weigh in these systems and make something fair. Is fairness
          possible in the world? I'm not sure.
        </p>

        <p className="interviewItalic">
          EA: Do you believe in the idea of life in the universe? Like aliens
          and different worlds beyond Earth or something like that?
        </p>

        <p>
          AB: I believe the most important forms of lives are pretty much
          boundless. Yes we are finding that there are solar systems that can
          harbor life. Yes, I believe there are creatures, like insects, like
          humans, like higher forms of life everywhere. But we all have one
          Earth, or 20 Earths. I feel yes, the most beautiful forms of life for
          me are invisible. I feel that yes, the possibilities in the worlds are
          endless. I believe that it's infinite. The universes are infinite, and
          nothing can make the world bounded to one Earth. Yes there are plenty
          planets that are habitable, but the most beautiful thoughts of humans
          and creatures and aliens, they’re all in our heads. Since the dawn of
          time we had dreams about higher beings. The ancient civilizations
          believed God or gods, or deities, angels. I don't understand why we
          can't live in a world where angels and machines and science and
          religion can live together, because if you can send a message on the
          mobile phone, or if we are building AI machines.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F79484b8d-6970-4d70-8355-28dd9fb7a82f_661x662.jpeg"
          alt="MC Artboy"
        />
        <p>
          I believe it's much possible that dragons or unicorns existed before
          they were extinct. I don't know, I believe anything is possible. We
          need just to find a way to meet our friends, and at least if aliens
          existed, if they are our friends it's cool. If they come to take our
          resources or bomb us…it’s going to be tough. Not everyone in the
          universe can be our friends. If it becomes a Star Wars or galactical
          war where everybody fight for 10 million years, I'm not sure I want to
          be part of the alien culture. I believe, yea, there are possibilities
          where we can live in peace and harmony with ourself, the peace comes
          within. Especially to go outside of the limits of our solar systems we
          need to find the faith and the peace inside ourself. The travel
          outside and the travel inside is very different, it's a question of
          direction.
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/3dy4XM23cFI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p>
          When I see nature, I see the the limitless possibilities…So yes in
          many ways there are pretty much all forms of life in the universe, and
          there are planets that are habitable everywhere, science is
          discovering it right now. But in a way, what some people said before
          the Bible times, that humanity, humans, the forms of human is pretty
          much the medium of all forms of life, which is a controversial idea.
          Is that you can have higher forms of life in the universe, but humans
          is still the shape in the universe. According to some ancient texts
          humans were the stars…But yea I believe humans is pretty much amazing
          in itself, and the world is already big, we have friends everywhere,
          and we can have friends everywhere in the galaxy, we can communicate
          with them, we can find ways to make deals, and to be in peace, we can
          travel. The Earth is already beautiful as it is.
        </p>

        <p className="interviewItalic">
          EA: It reminds me of, there's a scientist named Michio Kaku, I think
          he teaches at a university in New York. He has different theories
          about civilizations that master space travel. He says in order for a
          civilization to master space travel they have to be able to get over
          the whole idea of making a war and fighting with other people, because
          otherwise they would end up destroying themselves. And so there's this
          whole idea that there's other civilizations, other worlds out there
          that have already kind of mastered their flaws.
        </p>

        <p className="interviewItalic">
          I guess the idea is that on Earth we're still learning how to
          understand ourselves, and we haven't completely mastered ourselves yet
          to be able to really travel into the universe. That's one of the ideas
          which makes sense, I mean we do have a lot of wars throughout the
          planet, and we are constantly fighting with ourselves throughout
          history. One of the hopes that a lot of people have is that hopefully
          we'll be able to learn to get along with each other. It goes back to
          what you were saying about different paths to enlightenment and all
          that sort of stuff…I was going to ask you, do you like Jimi Hendrix?
        </p>

        <p>
          AB: Yes, I do like Jimi Hendrix. I think things change though. There
          are friends that I have that I believe make amazing works, but I
          believe in those times during the 80s, 70s, 60s, 90s, there were great
          artists that never made…and there are some icons that made the world
          different by their names and there are some people that made amazing
          music, art, and they are not famous. I feel the club that we need to
          enter for people to speak about our deaths, after our deaths also;
          it's really cool to be renowned for three, four, 20 generations, like
          maybe Jimi Hendrix will speak for three or four more generation.
          Before there were books about some pretty amazing people during
          history and we forgot about them, but we should not forgive ourselves
          if we forget the most powerful names in music history, philosophical
          history…
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F34367355-8411-4fb6-be9d-4468a989f5d0_1192x604.jpeg"
          alt="MC Artboy"
        />
        <p>
          I feel there are artists that will never be renowned because they were
          not that iconic, but they made pretty much amazing works. There are
          artists that maybe are not that famous, or maybe a little bit famous,
          there are so much information in the world, some new ways to discover
          music, arts and poetry, and all these art forms. We meet them through
          a screen, through a projection on a computer, and yea there are
          artists that I respect, but my friends and those people that I meet,
          that I speak to in America, in Asia through the internet- make not
          especially the greatest works, or maybe they make amazing works, and I
          see their works and I say, woah that matters, because there is
          interest, there is communication. I speak with them, and I feel
          communication has become very important in the way we approach fame
          now. Like on Twitter people can interact with the people that they
          really love, they can comment on their thoughts.
        </p>

        <p>
          So things have changed now we do not especially live in the same way
          that we lived in the 20th century where we venerated David Bowie,
          Prince, Jimi Hendrix, they were icons of music because they were cool,
          they had a nice project, they are nice face. But now the interaction
          with the stars, the interaction with the heroes that we have through
          Twitter or through internet, we can communicate with anybody in the
          world. It's pretty much a question of the number of views we have, or
          maybe the way we speak, or maybe the curriculum vitae we have, we can
          communicate with these people and the world in the 21st century is not
          the same as the 20th century. Where there were no communication with
          the heroes that we had, now we can communicate with anybody, we can
          speak to anybody, we can critique everybody, we can cancel anybody
          that we do not like, that's important now because we have the power…So
          yea it's not the same world that we had before and now.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fe8a58b06-64e8-4fd5-96dc-72c785e78ec0_1459x539.jpeg"
          alt="MC Artboy"
        />
        <p className="interviewItalic">
          EA: …Is illuminatrix one of your projects?
        </p>

        <p>AB: Yea, my latest project yea.</p>

        <p className="interviewItalic">
          EA: And that's based off of different ideas about like the future of
          the world or something?
        </p>

        <p>
          AB: Yea, sure. I'm launching a series of mixtapes. I try to express
          the future, the technology and the spirit of illumination. And in the
          movies The Matrix, but also the ways to communicate with the future.
          And I felt that the philosophy needed a facelift, so I decided to
          change a little bit my direction and becoming fully aware of my
          possibilities. So in music, in visuals, and art of poetry, I want to
          be part of the 21st century and to make something which is deep, but
          also which is shapeless, anamorphic, ecliptic, and that is boundless
          to imagination and possibilities. And I felt that the Illuminatrix
          should be a theme that I speak about, but also that I try to portray
          arts, or graphics, or visuals, video clips, music.
        </p>

        <p className="interviewItalic">
          EA: Do you think any of your music, or different spoken word poetry
          stuff that you do will ever be on a vinyl record?
        </p>

        <p>
          AB: Is it relevant in the new age? Yea I guess, but I tried to grow
          slowly the words, making the songs that I do worthy to be heard by
          many. I feel I’m relevant, but am I really relevant for the masses?
          Like yea I do something, but even if I like it, is it worthy? Is it
          Jimi Hendrix like, is it David Bowie like, is it Prince like? It is
          hard to make it worthy for everyone. There are so much music every day
          now that is released into the atmosphere, but visually can we be an
          icon for humanity? Can we be something that is worthy of remembrance?
          Are we going to be remembered in 10 years, in 5 years, in 50 years?
          That's pretty tough, I try to be the hero of the future at the moment,
          or I try to be the mascot of futurologists, or maybe I try to be
          Artboy Invisible which is AI. I try to be somebody that we can trust
          if we want to understand the past and the future, and travel through
          time. We need to be something which is remembered, because an artist
          that's what he wants to be, he fights against the fact that time goes
          on, and the music has to be heard again and again for pretty much long
          time. Some music will not be remembered, some people will not be
          remembered.
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/-VRyn0JzcZY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p>
          Yea music is a tough game, because people still listen to music from
          the 90s but for how long? Why? Is it relevant? When I approach music
          I'm not sure that I want to make music for the sound and the flow of
          it. People they make music for feel good. I try to make music to teach
          a way for people to remember that they need to think, they need to
          fathom the existence. I try to be relevant, visually I try to speak
          about the future, many references in my music is about time, like
          2068, 2077. I tried to predict the future, I tried to speak about new
          ways of thinking, many approaches in writing, I use always different
          ways to write…
        </p>

        <p>
          And yea the music is supposed to feel good, but I am here to make
          music that makes us think about the future, or about poverty, or about
          suffering, about economy, about politics. I love to speak about
          politics in music because it's difficult to make it, I use it as
          activism. I love to change the perception of humans about their place
          in existence. I want people to feel important, I want to also that
          they feel that their ego and their personal lives are nothing compared
          to the beauty and the vastness of this whole universe…Is nothing wrong
          to just feel good. Music can make you suffer also, and it's good
          because it's like a movie…Music can make us experience things like a
          movie can make us experience things that we never experienced.
        </p>

        <p className="interviewItalic">
          EA: What other projects are you working on?
        </p>

        <p>
          AB: …I feel I try to work as much as I can to find new ways to
          communicate by art and my creativity. I always say to my friends that
          I experience everything. I've been living the possibilities of
          existence, but I feel I'm changing myself to discover a new way to
          express my creativity…So I'm studying new ways to create the future. I
          wrote books, I make music, I make graphics…
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F3348b8dd-2b9e-4c53-97fe-7d7aa6c3956e_418x537.jpeg"
          alt="MC Artboy"
        />
        <p className="interviewItalic">
          EA: Have you heard of the French author Louis-Ferdinand Céline?
        </p>

        <p>AB: Yes.</p>

        <p className="interviewItalic">
          EA: He wrote Death on the Installment Plan, have you heard about that?
        </p>

        <p>AB: Tell me about it.</p>

        <p className="interviewItalic">
          EA: It was I guess the whole idea of just working and trying to get
          money, but at the same time he was writing his book, and he was a
          writer and stuff. And so I guess it was like you know difficulties in
          life and stuff and, I haven't read it in a while so I might not
          actually be explaining it that accurately, but I was just wondering,
          have you read a lot of French literature?
        </p>

        <p>
          AB: Yea, yea. I'm interested by the books that make me think about a
          better way to think…How to explain this? I feel the world is changing,
          and books that we read 20, 30 years ago are not the same books that we
          would read for the new age of the 21st Century. So before I read these
          books, would I read them again? Not especially. I feel that some
          fictions and some books about beginning of the 20th century were great
          to read before, but now there are other books that I would read. Like
          now it’s much more about, in this decade, these past five years, many
          books that I read was mainly about techniques of self-improvement, to
          become a better skilled artist, or a better writer, or techniques how
          to write a better book. When I was young I read a lot of poetry,
          French poetry. I read Nietzsche, I read much philosophers, much poets,
          and at that time until some years ago that was really my fascination,
          so much so that it was like art. When I was in all these museums I
          started to be pessimistical about the power of art, like too much good
          things are going to overload the mind. At some point I read many but
          now I would spend my time on other things.
        </p>

        <p className="interviewItalic">
          EA: You also draw right? You make art that you draw? How did you start
          doing that?
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/vCvDZ2BQBmY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p>
          AB: I had my first mobile phone very late, like I didn't use mobile
          phone in the beginning, and my first computer I had it at the age of
          26 I guess. And I started drawing using Photoshop and making digital
          art. And I studied a little bit and made it, and started, and I was
          exhibited. I worked for a group exhibition with Nike, Nike football. I
          was in the Art Science Museum in Marina Bay Sands in Singapore. I
          exhibited USA group exhibitions, in UK, Ukraine. I use my work for
          activism, I made portraits of the Queen of England it was exhibited in
          Ukraine. I was in the newspaper with a portrait of Margaret Thatcher…I
          was also in magazines in Australia, exhibitions in Malaysia. Art can
          change the world, that's what I believe, that's what I do. I try to
          change the world even if I know that the world cannot be changed, but
          we are there to be guardians of this Earth, of this civilization. I
          try to help…I did political artworks since 2010. Since 2015 I stopped,
          I started with words…I did what I can and I will continue to do what I
          can do to help.
        </p>

        <p className="interviewItalic">
          EA: It reminds me of this quote by Jacques Fresco, I think he was an
          architect, he said, "if you think you can't change the world it just
          means you're not one of those that will." There's something meaningful
          that you said about wanting to create a change in the world, or change
          the world somehow. I guess everybody wants to do that, maybe that's
          kind of like a repeating story throughout history. It's like a
          philosophical thing but maybe it's also like something that everybody
          wants to do in some way.
        </p>

        <p className="interviewItalic">
          There's different ways to explain it and maybe I'm just only
          explaining part of it, but it also reminds me of the song from the
          band 10 Years After called I'd Love To Change The World. I guess it
          goes back or goes forward to the whole futurism, like futurologists
          type thing, trying to anticipate what the future is going to be like.
          But at the same time it's a hard thing to think about, it's like
          intimidating in a way. And there's the philosopher Alfred North
          Whitehead I think he said, “it's the business of the future to be
          dangerous.”
        </p>

        <p>
          AB: Yea, I had this in my mind. I thought that it can be scary, we can
          be scared if we are not prepared. If we think that we can change the
          world we need to be scared, because if we’re scared we want to change
          the world. If you're not scared we will accept the status quo and you
          say, yea that's good, that's going to happen, that's all right. That's
          two philosophies, we can think we can change the world, or we can
          accept that the world is going to change itself. Are you going to be
          the one that changed the world, or are others going to be the
          narrators of your own story? Are you the narrator of change, or are
          you just the spectator of change?
        </p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Bsc8EwkX9lg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p className="interviewItalic">
          EA: Yea exactly…I remember from one of the music videos that you made
          you have a line that says, “I'm a human being and I will not be
          offended.” Maybe you could talk a little bit about that?
        </p>

        <p>
          AB: Yea because I feel that we are supposed to be strong, because the
          world is going to be tough with you if you're not strong. And yea
          people they just feel offended for many things. Like if we are weak,
          it can be cute to be weak, a flower, a horse, a daisy, a lotus, beauty
          is weak. When you see a flower, when we see beautiful painting, a
          beautiful person, most of the beauty is weak, but the strong, when we
          witness something that has strength, like a storm, like a muscular
          person, like somebody that is massive, that's also a form of beauty.
          There are two types of beauties. And perhaps I have chosen not to be
          offended by life, by others, by critics. If you critique me, myself I
          would say okay, I would listen to it. Some people they are so intimate
          with their own personal story, with their own personal narrative that
          they wouldn't be able to pursue a critique from friends, from
          contemporaries. It's hard to be somebody that accepted other people
          who do not have the same point of views than themselves. So yea I will
          not be offended by other people that are strong like myself, because
          it's competitive. Strength is competitive. If I'm strong, I need
          somebody strong, it's a competition, strength is a competition. So yea
          I will not be offended by other people that are strong too. I will say
          yea, I will listen.
        </p>

        <p className="interviewItalic">
          EA: It reminds me of what this writer James Baldwin said, he said, "It
          is time to ask very hard questions and take very rude positions no
          matter the price." So there's almost that idea of, I guess both of the
          things at the same time: not being offended or not constantly feeling
          like you're offending someone? I don't know if that makes sense. So
          2068, was that something that you wrote about?
        </p>

        <p>
          AB: That was the the title of my book in French about philosophy. So
          that was the idea that 1968 in France would become 2068 in the future,
          in the future revolution that would happen in futurology, technology,
          but also in the power of literature and literacy.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fdca08f57-188a-4ccd-88c0-32970bd0bdba_751x750.jpeg"
          alt="MC Artboy"
        />
        <p className="interviewItalic">
          EA: Yea that's interesting I hadn't thought about it in terms of 1968,
          but that kind of adds a different dimension to it. 1968 was definitely
          a year of protests from what I remember, I think I have a book about
          it. Oh, go ahead.
        </p>

        <p>
          AB: That was a year for the culture, for the power of people, for the
          end of wars, for many things, for people to express themselves and
          become who they really wanted to be. We had the counter revolution or
          like it's much more different from what we expected, what we have now.
          People are not happy, many people…are not happy with the direction
          that France is taking now in politics, or with international globalist
          agenda or perhaps the ways of economy, or like there were pretty much
          people that were not happy at all. And yea in 1968 it was really about
          existentialism, about to exist, and to become who France was really
          wanting to be. And things changed now, it's not the same, we took a
          different path from there. The motto of 1968 was, “under the pavement,
          the sun.”
        </p>

        <p>
          And yea people wanted to be free. Now in the future it's more
          technology, it's more about machines, and yea I'm not sure what is
          going to happen in 2068, but I tried to write about it. I tried to
          show it in images or videos, I have tracks in music that speaks about
          the future, or that shows the future, that speaks about politics about
          the future. It's hard to be right about what we speak about…I'm happy
          to be able to explore myself, my mind, my imagination, and to speak
          about the endless doubts and certain doubts that we have before we
          reach the pinnacle of saying, okay it's going to be this, this is
          going to be the future that I want. I want to find myself the future
          that I really want, I want to be able to describe it, describe my own
          utopia. I want to be able to say, that's the future I want, and that's
          pretty tough because nobody has any idea what’s the best images that
          can define it, but I'm trying as an exercise for myself to find what
          is the definition of what I want.
        </p>

        <p>
          What is what I want? It's very hard to define. People they want
          something, good position, like a car or a house. Myself I want
          something invisible, I want to create something that never existed, I
          want a new form of beauty, I want to invent new colors, I want to find
          a technology that would make the world more beautiful, and this
          technology is invisible. Pretty much the future for what I have in
          mind is the possibility that we create new forms of arts that are not
          paintings, that are not YouTube videos. I believe that art is just at
          the beginning of art, there will be new forms of artforms that will
          not be in France in the museum, there will be new forms of arts that
          will change the way that we communicate, but also that we express what
          is the definition of beauty. And some of beauty, or the inner most
          profound meaning of beauty is that some part of artwork or performance
          is pretty much invisible to the eye.
        </p>
        <img
          src="https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F65253716-bb4d-49f9-9774-eed954a3bdbd_750x743.jpeg"
          alt="MC Artboy"
        />
        <p className="interviewItalic">
          EA: Hmm, yea that's interesting. Do you know anything about the
          protests that were going on in France like a couple years ago? Is that
          something that's influenced you?
        </p>

        <p>
          AB: Yea, there are societies that are allowed to protest, there are
          societies that are not allowed to protest…I'm not sure if we'll ever
          find a way where the world will be secure enough to forever having
          solutions for world’s problems as they need to be exhibited. I believe
          as long as we will be authorized to protest there will always be
          protests.
        </p>

        <p className="interviewItalic">
          EA: Have you ever heard anything about the underground tombs? I see it
          on TV sometimes.
        </p>

        <p>
          AB: Yea we see it on TV. My friend wanted to bring me there, but I
          didn't have the time yet to go, but I will go over there.
        </p>

        <p className="interviewItalic">
          EA: It's definitely mysterious and seems a little bit ghostly or
          whatever. Anyway I was just wondering…Maybe you could tell me a little
          bit about the glasses that you were trying to make? It was like three
          lens glasses that were inspired by Prince's glasses or something?
        </p>

        <p>
          AB: Yea I have a long story about these glasses, but now they’re
          commercialized. In United States in some shops you can buy three-eyed
          glasses. But what I wanted to make was augmented reality glasses, like
          Photoshop effects that can can be seen through our eyes. So for
          example if you open Photoshop you can have different effects like the
          brightness, or the saturation, or effects with colors. And so I wanted
          to produce glasses that can allow us to see the world through
          different inverted colors, effects that you can see in Photoshop, or
          with this software that allow us to see the world differently…
        </p>

        <p className="interviewItalic">
          EA: I really have to thank you again just for taking the time out of
          your day and your patience and everything.
        </p>

        <p>AB: Sure man, thanks, thanks a lot.</p>
      </div>

      <br />
      <br />
      <br />
      <h3>
        <Link to="/">artnow.world</Link>
      </h3>
      <br />
    </Layout>
  );
};

export default Artboy;
